.about-image-slider
{
	background-color: get-color(ad-gray);
	margin: 0;

	position: relative;

	.slide-container
	{
		max-width: 800px;
	}

	.bx-wrapper
	{
		border: 0;
		box-shadow: none;
		background-color: transparent;
		margin: 0 auto;

		li
		{
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			overflow: hidden;
		}

		.bx-pager
		{
			display: block;
			position: absolute;
			top: 90%;
			padding-top: 0;			
		}

		.bx-pager.bx-default-pager a {
			background: #fff;
			opacity:0.4;
		}
		.bx-pager.bx-default-pager a.active {
			opacity: 1;
		}
	}

	.slide-title
	{
		font-family: get-font(copy);
		//font-weight: get-font-weight(semi-bold);
		font-style: italic;
		@include font-size(30px);
		line-height: 36px;
	}

	.slide-text
	{
		font-family: get-font(copy);
		font-style: italic;
		@include font-size(20px);
		line-height: 28px;

		margin: 2rem 0;
	}

	.slide-link
	{
		font-weight: get-font-weight(semi-bold);
		a:hover { 
			background-color: #fff;
		}
	}

	.pager-left, .pager-right
	{
		display: block; 
		position: absolute;
		bottom: 45%;
		text-align: right;
	}

	.pager-left
	{
		left: 1rem;
	}

	.pager-middle
	{
		display: none;
	}

	.pager-right
	{
		right: 1rem;
	}

	p.xlarge
	{
		@include font-size(18px);
		line-height: 1.45;
		font-family: get-font(heading);
		font-weight: get-font-weight(ultra-bold);
	}

	.bx-viewport li {
	min-height: 300px;
	min-width: 100%;
	}
}




@media all and(min-width: get-breakpoint(bitty))
{
	.about-image-slider
	{
		.pager-left, .pager-right, .pager-middle
		{
			display: block; 
			position: absolute;
			bottom: 2rem;
			text-align: right;
			padding-right: 3rem;
		}

		.pager-left
		{
			right: 7rem;
		}

		.pager-middle
		{
			right: 3rem;
		}

		.pager-right
		{
			right: 1rem;
		}

		.bx-pager
		{
			display: none;
		}

		.bx-pager.bx-default-pager a {
			display: none;
		}

		.slide-title
		{
			@include font-size(32px);
			line-height: 35px;
		}

		.slide-text
		{
			@include font-size(22px);
			line-height: 35px;

			padding-left: 8rem;
		}

		.slide-link
		{
			padding-left: 8rem;
			margin-bottom: 2rem;
		}

		.bxslider
		{
			li
			{
				min-height: 480px;
				height: 480px;
			}
		}

		p.xlarge
		{
			@include font-size(30px);
			line-height: 1.33;
		}
	}
}
