.crisis-landing
{
	.content
	{
		background-color: white;
	}

	.crisis-landing-header
	{
		@include font-size(24px);
		position: relative;
		margin-left: 66px;
		padding-top: 8px;

		&:before
		{
			content: '';
			position: absolute;
			left: -66px;
			top: 0;
			width: 41px;
			height: 34px;

			background: url('../images/hand-icon.svg') no-repeat center center;
		}
	}

	.disaster
	{

		border: 1px solid #CCC;
		margin-bottom: 50px;

		.img
		{
			position: relative;
			img
			{
				display: block;
			}

			.overlay
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;

				background-color: rgba(0,0,0,.5);
				transition: .3s opacity;

				.overlay-content
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

			}

			&:hover
			{
				.overlay
				{
					opacity: 1;
				}
			}
		}

		.text
		{
			padding: 10px;
		}

		.title
		{
			@include font-size(16px);
			font-family: get-font(heading);
			text-transform: uppercase;
		}
		
		.desc
		{
			@include font-size(14px);
			margin-top: 3px;

			margin-bottom: 0;

			@media (hover: none) {
				margin-bottom: 24px;
			}
		}

		&.empty
		{
			height: 0;
			line-height: 0;
			border: 0;
		}
		
		&.accomplished
		{
			img
			{
				filter: grayscale(1);
			}

			.overlay
			{
				opacity: 1;
			}

			.overlay-content
			{
				text-align: center;
				color: get-color(white);
				font-family: get-font(heading);
				@include font-size(16px);
				text-transform: uppercase;

				.check
				{
					display: inline-block;
					@include font-size(35px);
					border-radius: 100%;
					background-color: #25b1d0;
					margin-bottom: 10px;
					padding: 15px;
				}
			}
		}
	}
}

@media all and(min-width: get-breakpoint(semi-small))
{
	.crisis-landing
	{
		.disaster-container
		{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.disaster
		{
			width: 48%;
			max-width: none;
		}
	}
}

@media all and(min-width: get-breakpoint(large))
{
	.crisis-landing
	{
		.disaster-container
		{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.disaster
		{
			width: 30%;
			max-width: none;
		}
	}
}
