header
{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 101;

	svg
	{
		display: block;
	}

	.mobile-header
	{
		background-image: url('../../assets/images/green-texture.jpg');

		padding: 0 2rem;

		.contain
		{
			padding-top: 0;
			padding-bottom: 0;
		}

		@include clearfix();

		.logo
		{
			padding: 2.05rem 1rem;
			margin-left: -2rem;
			width: 150px;
		}

		.logo, .login, .menu
		{
			height: 90px;
		}

		.logo
		{
			float: left;
			background-color: get-color(white);
			//width: 225px;
		}

		.menu
		{
			width: 60px;
		}

		.menu
		{
			float: right;

			@include font-size(30px);
			color: get-color(white);
			text-align: center;

			padding: 2rem 0;
		}

		.hamburger
		{
			height: 27px;
			width: 30px;
			display: block;
			border-top: 5px solid get-color(white);
			border-bottom: 5px solid get-color(white);
			position: relative;
			margin-left: auto;

			top: 50%;
			transform: translate(0, -50%);


			cursor: pointer;

			&:before, &:after
			{
				transition: transform .3s;
			}

			&:before
			{
				height: 5px;
				width: 100%;
				display: block;
				background-color: get-color(white);
				content: '';
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
			}

			&:after
			{
				height: 5px;
				width: 100%;
				display: block;
				background-color: get-color(white);
				content: '';
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
			}

			&.open
			{
				border-width: 0;

				&:before
				{
					transform: translate(0, -50%) rotate(45deg);
				}

				&:after
				{
					transform: translate(0, -50%) rotate(-45deg);
				}
			}
		}
	}
}

.desktop-header
{
	display: none;
	background-image: url('../../assets/images/green-texture.jpg');

	//max-width: $outer-container-max-width;
	margin: 0 auto;

	text-transform: uppercase;

	.logo
	{
		padding: 2rem 3rem;
		background-color: get-color(white);
		width: 250px;
		height: 116px;
		float: left;
	}

	.nav-container
	{
		height: 116px;
	}

	.primary
	{
		height: 116px;
		color: get-color(white);
		font-weight: bold;

		.flex
		{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 116px;
			padding-right: 3em;
		}

		li
		{
			display: inline-block;
			text-align: center;
			margin-right: 45px;
			padding: 2.2rem 1rem 1.7rem 1rem;

			transition: background-color .5s;

			a
			{
				display: inline-block;
				width: 100%;
				padding-bottom: .5rem;
				border-bottom: 3px solid transparent;
				transition: border-color .3s;
			}

			.fa
			{
				margin-right: 1rem;
			}
			
			&:last-child
			{
				margin-right: 0;

				a
				{
					border-bottom: none !important;
				}
			}

			&:hover, &.active
			{
				a
				{
					border-bottom-color: white;
				}
			}
		}
	}
}

@media all and(min-width: get-breakpoint(medium))
{
	.mobile-menu, .mobile-header
	{
		display: none;
	}

	nav.secondary
	{
		display: block;
	}

	.desktop-header
	{
		display: block;
		@include clearfix();
	}
}
