.full-story
{
	@media all and(min-width: get-breakpoint('small'))
	{
		.contain
		{
			.full-story-content
			{
				padding: 0px 20rem;
			}
		}
	}
}