.about
{
	.fund-details
	{
		@include font-size(16px);
		color: #8e8e8e;
		padding: 25px;

		strong
		{
			display: block;
		}

		p
		{
			margin: 12px 0;
			@include font-size(18px);
		}
	}
}
