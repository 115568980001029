@media all and(min-width: get-breakpoint(semi-small))
{
	.wysiwyg-left-half-column
	{
		width: 48%;
		margin-right: 2%;
		float: left;
	}

	.wysiwyg-right-half-column
	{
		width: 48%;
		margin-left: 2%;
		float: right;

		+ p, hr
		{
			@include clearfix();
		}
	}

	.wysiwyg-left-third-column,
	.wysiwyg-middle-third-column,
	.wysiwyg-right-third-column
	{
		width: 32%;
		margin-right: 2%;
		float: left;
	}

	.wysiwyg-right-third-column
	{
		margin-right: 0;

		+ p, hr
		{
			@include clearfix();
		}
	}

	.wysiwyg-first-quarter-column,
	.wysiwyg-second-quarter-column,
	.wysiwyg-third-quarter-column,
	.wysiwyg-fourth-quarter-column,
	{
		width: 24%;
		margin-right: 1%;
		float: left;
	}

	.wysiwyg-fourth-quarter-column
	{
		margin-right: 0;

		+ p, hr
		{
			@include clearfix();
		}
	}
}
