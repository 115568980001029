/**
 * Delete/Addition friendly. These are different libraries that I like and use
 * but don't use things you hate, remove what you don't want.
 * If you want to use Bootstrap strip out all of these guys. Normalize is
 * redundant (it's included in bootstrap) and Bootstrap comes with a grid.
 * Font awesome isn't needed either, so you can strip that out too, but I
 * personally like those fonts better and I know most developers end up putting
 * that in at some point anyway. Include-media is a great tool for working
 * with media queries, I think you should look into it, but again, with Bootstrap
 * it may not be needed.
 */

// Vendor .....................................................................
@import '../../node_modules/normalize.css/_normalize';
@import '../../node_modules/font-awesome/scss/font-awesome';
@import '../../node_modules/susy/sass/susy';
@import '../../node_modules/include-media/dist/include-media';

/**
 * These are just some functions and mixins that I end up bringing into
 * every project I create. Particulary I use the @include font-size() mixin
 * everwhere.
 */
// Functions & Mixins .........................................................
@import 'utilities/general-sass-functions';
@import 'utilities/general-sass-mixins';
@import 'utilities/ios_vunits';
//@import 'utilities/sprites'; // If you're using sprites uncomment this line


/**
 * This should be where you start editing. We have some simple defaults already
 * in _elements.scss and _variables.scss already, but they're still pretty
 * open to customize.
 */

// Base ........................................................................
@import 'base/variables';
@import 'base/elements';
@import 'base/input';
@import 'base/text';
@import 'base/tables';
@import 'base/wysiwyg-classes';
@import 'base/pagination';

/**
 * Most of your code *should* nicely fit into modules, but the truth is that
 * not everything is a module. Sometimes, things are really related to a specific
 * page. So, when you have content that is only for a specific page, it should go
 * into this section.
 * Now, if you have a small site one single _page.scss file should be enough.
 * However, if you've got a big site, don't try and cram everythning into one
 * file. Break your _page.scss up into logical page modules:
 * @example pages/login
 * @example pages/landing
 * Now this might seem silly to say, but try very hard to *not* use pages.
 * You want your CSS to be like little legos that can move from page-to-page
 * and section-to-section. When you find yourself dumping stuff in the
 * page section, ask yourself, 'would this be better as a module?' or
 * 'how can I make this so it works as a module?'
 */

// Page level styles ...........................................................
// example: @import 'pages/page';
@import 'pages/page';
@import 'pages/fonts';
@import 'pages/home';
@import 'pages/crisis';
@import 'pages/crisis-landing';
@import 'pages/about';
@import 'pages/full-story';
@import 'pages/donate';

/**
 * This is Sass, don't just dump eveything in a file called 'styles' or 'main' FFS.
 * Break your code up into individual modules. You'll be able to maintain it better
 * later on in life...you know...like 6 months from now once you have no idea
 * of how you put together and the client calls you needed help...your future
 * self will thank you.
 * Important thing to note about modules: they're discrete objects. They should
 * not rely on any other module. Someone should be able to come into this file
 * and move all the modules around and your site should work fine. No cascade.
 */

// Modules ....................................................................
// example: @import 'modules/example-module';
@import 'modules/header';
@import 'modules/mobile-menu';
@import 'modules/footer';
@import 'modules/donation-modal';
@import 'modules/email_alert_thanks_modal';

@import 'modules/page_modules/hero';
@import 'modules/page_modules/quote-block';
@import 'modules/page_modules/about-image-slider'

