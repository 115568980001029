/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
@mixin viewport-unit($property, $value, $delta: 0px) {
	#{$property}: calc(#{$value} + #{$delta});

	$unit: unit($value);

	@if (index((vw, vh, vmin, vmax), $unit) != null) {
		$devices: (
						(768px, 1024px), // iPad (all versions)
						(320px, 480px),  // iPhone 4
						(320px, 568px)   // iPhone 5, 5C, 5S
		);

		@each $device in $devices {
			$device-width: nth($device, 1);
			$device-height: nth($device, 2);

			$device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
			$device-query: "#{$device-query} and (device-width: #{$device-width})";
			$device-query: "#{$device-query} and (device-height: #{$device-height})";

			$delta: if($device-height == 568px, $delta - 108, $delta);

			$percent: $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533

			$percent-width: $device-width * $percent / 100;
			$percent-height: $device-height * $percent / 100;

			@if ($unit == vmin or $unit == vmax) {
				@media #{$device-query} {
					#{$property}: if($unit == vmin, $percent-width, $percent-height);
				}
			}
			@else {
				@media #{$device-query} and (orientation: portrait) {
					#{$property}: if($unit == vw, $percent-width, $percent-height + $delta);
				}

				@media #{$device-query} and (orientation: landscape) {
					#{$property}: if($unit == vw, $percent-height, $percent-width + $delta);
				}
			}
		}
	}
}
