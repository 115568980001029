#donation-modal
{
	//display: none;
	//position: fixed;
	//z-index: 113;

	//top: 50%;
	//left: 50%;

	//width: 600px;
	//max-width: 85vw;
	//max-height: 85vh;
	//overflow: scroll;
	//height: auto;

	//background-color: get-color(white);

	//transform: translate(-50%, -50%);

	.fa-close
	{
		position: absolute;
		top: 10px;
		right: 10px;
		@include font-size(25px);
		padding: .5rem;
		cursor: pointer;
	}
	
	.status-msg
	{
		margin-bottom: 1.5rem;
	}

	form
	{
		padding: 2rem;
		border-bottom: solid 1px #b3b6b4;
		//background: #ECEDEE;
	}
}

.donation-modal-intro
{
	padding: 3rem 10% 3rem 10%;

	h4
	{
		@include font-size(20px);
		margin-bottom: 0;
		color: get-color(ad-green);
	}

	p
	{
		@include font-size(15px);
		line-height: 1.33;
	}
}

#donation-modal-content
{
	//padding: 2rem;
	//margin-top: 3rem;
}

#card-errors, #card-expiration-errors, #card-cvc-errors
{
	margin-top: 1rem;
	color: get-color(ad-error);
}

@media all and (max-width: get-breakpoint(small))
{
	#donation-modal
	{
		//top: 0;
		//left: 0;
		//width: 100vw;
		//height: 100vh;
		//max-width: none;
		//max-height: none;
		//transform: none;
	}
}
