button, .btn
{
	display: inline-block;
	cursor: pointer;

	color: get-color(ad-green);
	font-family: get-font(copy);
	line-height: 1.38;
	@include font-size(15px);
	font-weight: bold;
	background-color: get-color(white);
	outline: 0;
	border: 0;

	text-align: center;
	padding: 12px !important;
	//min-width: 185px;
	width: auto;

	transition: all .3s;

	text-decoration: none !important;
	text-transform: uppercase;

	&:hover
	{
		background-color: #eff0ef;
	}

	&[disabled]
	{
		background-color: get-color(ad-light-gray);
		cursor: default;
	}

	&.secondary
	{
		padding: .7rem;
		min-width: 135px;

		background-color: transparent;
		color: get-color(ad-green);
		font-family: "Gunar-DemiBold", "Helvetica Neue", sans-serif;
		border: 2px solid get-color(ad-green);
		margin-top: 3rem;

		&:hover, &.active
		{
			color: get-color(white);
			background-color: get-color(ad-green);
		}

		&--small
		{
			@extend .btn.secondary;
			margin-top: 0;
			padding: .5rem;
			min-width: 100px;
		}
	}

	&-outline
	{
		@extend .btn;
		background-color: transparent;
		color: get-color(white);
		border: 3px solid white;

		&:hover
		{
			background-color: white;
			color: get-color(ad-black);
		}

		&--video
		{
			@extend .btn-outline;

			&:after
			{
				content: '';
				display: inline-block;
				position: relative;
				margin-left: 8px;
				top: 4px;

				width: 0;
				height: 0;
				border-style: solid;
				border-width: 9px 0 9px 15.6px;
				border-color: transparent transparent transparent #ffffff;
				transition: border-color .3s;
			}

			&:hover
			{
				&:after
				{
					border-color: transparent transparent transparent get-color(ad-black);
				}
			}
		}

		&--donate
		{
			@extend .btn-outline;
			border-color: get-color(ad-blue);
			color: get-color(ad-black);
			@include font-size(20px);
			min-width: 312px;

			&:after
			{
				@extend .fa;
				content: '\f061';

				display: inline-block;
				position: relative;
				margin-left: 16px;
			}

			&:hover
			{
				color: get-color(white);
				background-color: get-color(ad-blue);
			}
		}

		&--black
		{
			@extend .btn-outline;
			color: get-color(ad-black);
			border-color: get-color(ad-black);
			background-color: transparent;

			&:hover
			{
				color: get-color(white);
				background-color: get-color(ad-black);
			}
		}
	}

	&.no-arrow
	{
		&:after
		{
			display: none;
		}
	}
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
select,
textarea,
.StripeElement,
.__PrivateStripeElement-input
{
	padding: 1rem;
	width: 100%;
	box-sizing: border-box;
	outline: none;
	border: 1px solid #C1C3C7;
	border-radius: 0;
	font-family: get-font(copy);
	@include font-size(12px);
	line-height: 1.38;

	@include font-size(16px);

	&[disabled]
	{
		background-color: #EEEEEE;
	}

	@media all and (min-width: get-breakpoint(semi-small))
	{
		//width: auto;
	}
}

label
{
	display: block;
	font-family: "Gunar-Bold";
	@include font-size(15px);
	margin-bottom: 1rem;

	&.inline
	{
		display: inline-block;
		margin-right: 3rem;
	}

	&.thin
	{
		display: inline-block;
		margin-left: 0.5rem;
		vertical-align: top;
		font: {
			family: get-font(copy);
		}
		@include font-size(15px);
	}
}

.form-row
{
	margin-bottom: 3rem;

	.form-left, .form-right, .form-middle .form-left-3, .form-middle-3, .form-right-3, .form-a, .form-b, .form-c, .form-d, .form-e
	{
		margin-bottom: 3rem;
	}

	.form-a, .form-b, .form-c, .form-d, .form-e
	{
		float: none;
		width: auto;
		position: static;
	}

	.form-a
	{
		order: 5;
	}

	@media all and (min-width: get-breakpoint(bantam))
	{
		.form-left, .form-right, .form-middle .form-left-3, .form-middle-3, .form-right-3, .form-a, .form-b, .form-c, .form-d, .form-e
		{
			margin-bottom: 0;
		}
		.form-left
		{
			float: left;
			width: 46%;
		}

		.form-right
		{
			float: right;
			width: 46%;
		}

		.form-middle
		{
			margin-left: auto;
			margin-right: auto;
		}

		.form-left-3
		{
			float: left;
			width: 28%;
		}

		.form-middle-3
		{
			float: left;
			width: 28%;
			position: relative;
			left: 8%;
		}

		.form-right-3
		{
			float: right;
			width: 28%;
		}

		.form-a 
		{
			float: left;
			width: 2%;
		}

		.form-b
		{
			float: left;
			width: 23%;
			position: relative;
			left: 3%;
		}

		.form-c
		{
			float: left;
			width: 23%;
			position: relative;
			left: 4%;
		}

		.form-d
		{
			float: left;
			position: relative;
			width: 23%;
			left: 5%;
		}

		.form-e
		{
			float: left;
			position: relative;
			width: 23%;
			left: 6%;
		}
	}
}

.StripeElement {
	background-color: white;
}

input[type="radio"]
{
	appearance: none;
	outline: 0;
	border:none;
	background-color: transparent;
	@extend .fa;

	&:before
	{
		content: '\f1db';
	}

	&:checked
	{
		background-color: transparent;
		&:before
		{
			color: get-color(ad-light-green);
			content: '\f192';
		}
	}
}

input[type="checkbox"]
{
	appearance: none;
	outline: 0;
	border:none;
	background-color: transparent;

	@extend .fa;

	&:before
	{
		content: '\f096';
		font-size: 3rem;
	}

	&:checked
	{
		background-color: transparent;
		&:before
		{
			color: get-color(ad-light-green);
			content: '\f046';
		}
	}
}

.dropdown-label
{
	position: relative;
	//display: inline !important;

	&:after
	{
		@extend .fa;
		color: get-color(ad-black);
		position: absolute;
		right: 1.5rem;
		top: 50%;
		transform: translate(0, -50%);
		content: '\f078';
		text-align: center;
		pointer-events: none;
		display: block;
	}

	&--transparent-yellow, &.green-white
	{
		@extend .dropdown-label;

		&:after
		{
			color: get-color(white);
		}
	}
}

select
{
	appearance: none;
	color: get-color(ad-black);
	background-color: get-color(white);
	font-family: "Archivo";
	line-height: 1;
	@include font-size(15px);

	padding: 1.2rem 4rem 1.2rem 1rem;
	min-width: 260px;

	&.transparent-yellow
	{
		@extend select;
		color: get-color(ad-yellow);
		background-color: transparent;
	}

	&.green-white
	{
		@extend select;
		color: get-color(white);
		background-color: get-color(ad-light-green);
	}

	&::-ms-expand {
		display: none;
	}
}

.form_error, .error
{
	color: get-color(ad-error);
	display: block;
}

#type-error
{
	position: absolute;
	top: 2em;
	left: -10px;
}

.required-msg
{
	@include font-size(14px);
	color: get-color(ad-gray);
}

.currency-input
{
	position: relative;
	width: 180px;

	&:before
	{
		content: '$';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 1.6rem;
		height: 100%;
		background-color: #AAA;
		padding: 1rem 5px;
		line-height: 1.38;
	}

	&:after
	{
		content: '.00';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1.6rem;
		height: 100%;
		background-color: #AAA;
		padding: 1rem 5px;
		line-height: 1.38;
	}

	input
	{
		width: 100%;
		padding-left: 39px;
		padding-right: 47px;
		text-align: right;
	}
}
