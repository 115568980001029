.crisis
{
	h1
	{
		@include font-size(34px);
	}
	
	.crisis-container
	{
		.crisis-content
		{
			margin-bottom: 6rem;

			.crisis-form
			{
				width: 38%;
			}

		}

		.gallery
		{
			text-align: center;
		}

		.gallery-container
		{
			margin: 1.5rem auto;

			display: flex;
			justify-content: space-between;
			align-items: center;

			.prev, .next
			{
				@include font-size(28px);
				cursor: pointer;
				line-height: 110px;
				padding: 0 1rem;

				display: none;
			}
		}

		.large-images
		{
			position: relative;
			padding-top: 65.706%;
			max-width: 670px;
			margin: 0 auto;

			img
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				opacity: 0;
				transition: opacity .3s;

				&.active
				{
					opacity: 1;
				}
			}
		}

		.gallery-slider
		{
			display: flex;
			justify-content: space-between;

			flex: 1;

			img
			{
				width: 23%;
				align-self: baseline;
				user-select: none;

				border: 4px solid transparent;
				transition: border-color .3s;

				&.active
				{
					border-color: get-color(ad-green);
				}
			}
		}
	}

	.in-kind-container 
	{
		left-margin: 2rem;
		right-margin: 2rem;
	}
}

@media all and(min-width: 860px)
{
	.crisis-container
	{
		.contain
		{
			display: flex;
			justify-content: space-around;
			align-items: flex-start;

			.gallery
			{
				order: 1;
				width: 58%;
				margin-right: 8%;
			}

			.crisis-content
			{
				order: 2;
				width: 38%;
				margin-bottom: 0;

			}

			.gallery-container
			{
				max-width: 650px;

				.prev, .next
				{
					display: block;
				}
			}

			.gallery-slider
			{
				max-width: 570px;
			}
		}
	}
}
