/**
 * @license
 * MyFonts Webfont Build ID 3207539, 2016-04-21T11:22:04-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gunar-Black by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/black/
 * 
 * Webfont: Gunar-BlackItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/black-italic/
 * 
 * Webfont: Gunar-Bold by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/bold/
 * 
 * Webfont: Gunar-BoldItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/bold-italic/
 * 
 * Webfont: Gunar-DemiBold by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/demi-bold/
 * 
 * Webfont: Gunar-DemiBoldItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/demi-bold-italic/
 * 
 * Webfont: Gunar-ExtraBold by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/extra-bold/
 * 
 * Webfont: Gunar-Light by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/light/
 * 
 * Webfont: Gunar-LightItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/light-italic/
 * 
 * Webfont: Gunar-Italic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/italic/
 * 
 * Webfont: Gunar-ExtraBoldItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/extra-bold-italic/
 * 
 * Webfont: Gunar-Heavy by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/heavy/
 * 
 * Webfont: Gunar-Medium by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/medium/
 * 
 * Webfont: Gunar-HeavyItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/heavy-italic/
 * 
 * Webfont: Gunar-Thin by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/thin/
 * 
 * Webfont: Gunar-MediumItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/medium-italic/
 * 
 * Webfont: Gunar-Regular by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/regular/
 * 
 * Webfont: Gunar-ThinItalic by The Northern Block Ltd
 * URL: http://www.myfonts.com/fonts/northernblock/gunar/thin-italic/
 *
 * Webfont: Serifa-Lig by URW++
 * URL: http://www.myfonts.com/fonts/urw/serifa/t-light/
 *
 * Webfont: Serifa-Bol by URW++
 * URL: http://www.myfonts.com/fonts/urw/serifa/t-bold/
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3207539
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2013 by Jonathan Hill. All rights reserved.
 * 
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/30f173");
@import url("//hello.myfonts.net/count/3018a1");

  
@font-face {font-family: 'Gunar-Black';src: url('../fonts/gunar/30F173_0_0.eot');src: url('../fonts/gunar/30F173_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_0_0.woff2') format('woff2'),url('../fonts/gunar/30F173_0_0.woff') format('woff'),url('../fonts/gunar/30F173_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-BlackItalic';src: url('../fonts/gunar/30F173_1_0.eot');src: url('../fonts/gunar/30F173_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_1_0.woff2') format('woff2'),url('../fonts/gunar/30F173_1_0.woff') format('woff'),url('../fonts/gunar/30F173_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Bold';src: url('../fonts/gunar/30F173_2_0.eot');src: url('../fonts/gunar/30F173_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_2_0.woff2') format('woff2'),url('../fonts/gunar/30F173_2_0.woff') format('woff'),url('../fonts/gunar/30F173_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-BoldItalic';src: url('../fonts/gunar/30F173_3_0.eot');src: url('../fonts/gunar/30F173_3_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_3_0.woff2') format('woff2'),url('../fonts/gunar/30F173_3_0.woff') format('woff'),url('../fonts/gunar/30F173_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-DemiBold';src: url('../fonts/gunar/30F173_4_0.eot');src: url('../fonts/gunar/30F173_4_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_4_0.woff2') format('woff2'),url('../fonts/gunar/30F173_4_0.woff') format('woff'),url('../fonts/gunar/30F173_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-DemiBoldItalic';src: url('../fonts/gunar/30F173_5_0.eot');src: url('../fonts/gunar/30F173_5_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_5_0.woff2') format('woff2'),url('../fonts/gunar/30F173_5_0.woff') format('woff'),url('../fonts/gunar/30F173_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-ExtraBold';src: url('../fonts/gunar/30F173_6_0.eot');src: url('../fonts/gunar/30F173_6_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_6_0.woff2') format('woff2'),url('../fonts/gunar/30F173_6_0.woff') format('woff'),url('../fonts/gunar/30F173_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Light';src: url('../fonts/gunar/30F173_7_0.eot');src: url('../fonts/gunar/30F173_7_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_7_0.woff2') format('woff2'),url('../fonts/gunar/30F173_7_0.woff') format('woff'),url('../fonts/gunar/30F173_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-LightItalic';src: url('../fonts/gunar/30F173_8_0.eot');src: url('../fonts/gunar/30F173_8_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_8_0.woff2') format('woff2'),url('../fonts/gunar/30F173_8_0.woff') format('woff'),url('../fonts/gunar/30F173_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Italic';src: url('../fonts/gunar/30F173_9_0.eot');src: url('../fonts/gunar/30F173_9_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_9_0.woff2') format('woff2'),url('../fonts/gunar/30F173_9_0.woff') format('woff'),url('../fonts/gunar/30F173_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-ExtraBoldItalic';src: url('../fonts/gunar/30F173_A_0.eot');src: url('../fonts/gunar/30F173_A_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_A_0.woff2') format('woff2'),url('../fonts/gunar/30F173_A_0.woff') format('woff'),url('../fonts/gunar/30F173_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Heavy';src: url('../fonts/gunar/30F173_B_0.eot');src: url('../fonts/gunar/30F173_B_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_B_0.woff2') format('woff2'),url('../fonts/gunar/30F173_B_0.woff') format('woff'),url('../fonts/gunar/30F173_B_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Medium';src: url('../fonts/gunar/30F173_C_0.eot');src: url('../fonts/gunar/30F173_C_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_C_0.woff2') format('woff2'),url('../fonts/gunar/30F173_C_0.woff') format('woff'),url('../fonts/gunar/30F173_C_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-HeavyItalic';src: url('../fonts/gunar/30F173_D_0.eot');src: url('../fonts/gunar/30F173_D_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_D_0.woff2') format('woff2'),url('../fonts/gunar/30F173_D_0.woff') format('woff'),url('../fonts/gunar/30F173_D_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Thin';src: url('../fonts/gunar/30F173_E_0.eot');src: url('../fonts/gunar/30F173_E_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_E_0.woff2') format('woff2'),url('../fonts/gunar/30F173_E_0.woff') format('woff'),url('../fonts/gunar/30F173_E_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-MediumItalic';src: url('../fonts/gunar/30F173_F_0.eot');src: url('../fonts/gunar/30F173_F_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_F_0.woff2') format('woff2'),url('../fonts/gunar/30F173_F_0.woff') format('woff'),url('../fonts/gunar/30F173_F_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-Regular';src: url('../fonts/gunar/30F173_10_0.eot');src: url('../fonts/gunar/30F173_10_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_10_0.woff2') format('woff2'),url('../fonts/gunar/30F173_10_0.woff') format('woff'),url('../fonts/gunar/30F173_10_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gunar-ThinItalic';src: url('../fonts/gunar/30F173_11_0.eot');src: url('../fonts/gunar/30F173_11_0.eot?#iefix') format('embedded-opentype'),url('../fonts/gunar/30F173_11_0.woff2') format('woff2'),url('../fonts/gunar/30F173_11_0.woff') format('woff'),url('../fonts/gunar/30F173_11_0.ttf') format('truetype');}


@font-face {font-family: 'Serifa-Lig';src: url('../fonts/serifa/3018A1_0_0.eot');src: url('../fonts/serifa/3018A1_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/serifa/3018A1_0_0.woff2') format('woff2'),url('../fonts/serifa/3018A1_0_0.woff') format('woff'),url('../fonts/serifa/3018A1_0_0.ttf') format('truetype');}


@font-face {font-family: 'Serifa-Bol';src: url('../fonts/serifa/3018A1_1_0.eot');src: url('../fonts/serifa/3018A1_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/serifa/3018A1_1_0.woff2') format('woff2'),url('../fonts/serifa/3018A1_1_0.woff') format('woff'),url('../fonts/serifa/3018A1_1_0.ttf') format('truetype');}
