#email-alert-thanks-modal
{
	display: none;
	position: fixed;
	z-index: 113;

	top: 50%;
	left: 50%;

	width: 600px;
	max-width: 85vw;
	max-height: 85vh;
	overflow: scroll;
	height: auto;

	background-color: get-color(white);

	transform: translate(-50%, -50%);

	.fa-close
	{
		position: absolute;
		top: 10px;
		right: 10px;
		@include font-size(25px);
		padding: .5rem;
		cursor: pointer;
	}
}

.email-alert-thanks-modal-intro
{
	padding: 3rem 10% 3rem 10%;

	h4
	{
		@include font-size(20px);
		margin-bottom: 0;
		color: get-color(ad-green);
	}

	p
	{
		@include font-size(15px);
		line-height: 1.33;
	}
}
