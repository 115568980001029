.pagination
{
	@include font-size(15px);
	font-weight: bold;

	ul
	{
		margin: 0;
		padding: 0;
		float: right;

		list-style-type: none;

		li
		{
			display: inline-block;
			margin: 0 5px;

			&.active
			{
				color: get-color(ad-green);
			}

			&.disabled
			{
				color: get-color(ad-gray);

				a
				{
					cursor: default;
				}
			}
		}
	}

	&:after
	{
		display: table;
		content: '';
		clear: both;
	}
}
