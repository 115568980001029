.page-module--quote-block
{
	.contain--xnarrow
	{
		padding: 6rem 3rem;
	}

	.quote-mark
	{
		@include font-size(35px);
		height: 35px;
		display: block;
	}

	background-size: cover;
}

@media all and (min-width: get-breakpoint(semi-small))
{
	.page-module--quote-block
	{
		.contain--xnarrow
		{
			padding: 17rem 3rem;
		}

		.quote-mark
		{
			@include font-size(65px);
			height: 65px;
		}
	}
}

@media all and (max-width: get-breakpoint(bitty))
{
	.page-module--quote-block
	{
		.quote-mark
		{
			display: none;
		}
	}
}
