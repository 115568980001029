.mobile-menu
{
	position: absolute;
	z-index: 5;

	width: 100vw;
	height: 0;
	top: 90px;
	overflow: auto;

	background-image: url('../../assets/images/green-texture.jpg');

	transition: height .5s;

	&.open
	{
		@include viewport-unit(height, 100vh);
	}

	.menu-items
	{

		overflow: auto;
		height: 100%;

		li
		{
			position: relative;
			padding: 0 3rem;

			color: get-color(white);
			text-transform: uppercase;
			@include font-size(20px);
			font-family: "Archivo";
			font-weight: bold;

			a
			{
				display: block;
				position: relative;
				padding: 2rem 3rem;
			}

			&.has-children
			{
				&:before
				{
					@extend .fa;
					position: absolute;
					right: 3rem;
					top: 0;
					content: '\f067';
					height: 70px;
					text-align: center;
					padding: calc(2rem + 8px) 0 2rem 0;
					cursor: pointer;
				}

				.child-items
				{
					max-height: 0;
					overflow: hidden;
					transition: max-height .5s;
				}

				&.open
				{
					&:before
					{
						content: '\f068';
					}
				}
			}

			&.search {
				position: relative;

				input {
					text-indent: 2rem;
				}

				.fa-search
				{
					position: absolute;
					top: calc(3rem + 2px);
					left: 4rem;
					color: get-color(ad-light-gray);
					pointer-events: none;
				}
			}

			&:first-child
			{
				padding-top: 2rem;
			}

			&.secondary-menu-item
			{
				color: get-color(ad-light-gray);
				background-color: #FAFAFB;

				.fa
				{
					margin-right: 1rem;
				}
			}
		}

		.child-items
		{
			transition: height .5s;

			li
			{
				color: get-color(ad-light-gray);
				background-color: #FAFAFB;
				@include font-size(14px);

				a
				{
					padding-left: 5rem;
					padding-right: 4rem;
				}

				&:after
				{
					@extend .fa;
					position: absolute;
					right: 3rem;
					top: 0;
					content: '\f054';
					height: 70px;
					text-align: center;
					padding: calc(2rem + 8px) 0 2rem 0;
					cursor: pointer;
				}

				&:first-child
				{
					border-top: 1px solid get-color(ad-bg-gray);
					padding: 0;
				}

				&:last-child
				{
					border-bottom: none;
				}
			}
		}
	}
}
