/**
* A simple function to work with maps, it'll throw an error if the key doesn't
* exist in the map too.
* @param  String $key
* @param  Map    $map
* @return String
*/
@function get-by($key, $map) {
    @if isset($map, $key) {
        @return map-get($map, $key);
        } @else {
            @error "Unknown #{$key}";
        }
    }

/**
* Checks if the map/list has a specific key
* @param  String   $key
* @param  Map|List $map
* @return Boolean
*/
@function isset($map, $key) {
    @if map-has-key($map, $key) {
        @return true;
    }

    @return false
}

/**
* I wanted a simple way to always increment/decrement my z-index value
* @param  Integer $increment: 1
* @return Integer
*/
@function get-zindex($increment: 1) {
    @return $current-zindex + $increment;
}

@function z($increment: 1) {
    @return get-zindex($increment);
}
/**
* This will strip a unit off a number turning it from a string to a number
* @param  String $n
* @return Number
*/
@function get-value($n) {
    @return $n / ($n * 0 + 1);
}

/**
* Pulls the hex code out of the color map
* @param  String $name
* @return String
*/
@function get-color($name) {
    @return get-by($name, $colors);
}

/**
* Pulls the font-weight number out of the font-weight map
* @param  String $size
* @return Number
*/
@function get-font-weight($size) {
    @return get-by($size, $font-weights);
}

/**
* Pulls the value from the stack map
* @param  String $setting
* @return String
*/
@function get-font($setting) {
    @return get-by($setting, $stack-settings);
}

/**
* Calculates the correct unitless line-height based on
* the given font-size and line-height in pixels
* @param  String $font-size
* @param  String $line-height
* @return Number
*/
@function get-line-height($font-size, $line-height) {
    @return get-value($line-height / $font-size);
}

/**
* Retuns the pixel number of a specific breakpoint
* @param  String $name
* @return Number
*/
@function get-breakpoint($name) {
    @return get-by($name, $breakpoints);
}

/**
* Returns the REM value based on the pixel value
* @param  String $pxl
* @return Number
*/
@function rem($pxl) {
    $base-size: get-value(get-font(base-size) / 100);
    $base-px-size: 16 * $base-size;

    @return (get-value($pxl) / $base-px-size) + rem;
}

/**
 * Returns the REM values for margins/paddings in typical "box" notation: top, right, bottom, left OR top/bottom left/right
 * @param  List $mps...
 * @return String
 */
@function box($mps...) {
    $x: " ";
    @each $item in $mps {
        $x: $x + rem($item) + " ";
    }

    @return unquote($x);
}

