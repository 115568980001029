.donate
{
	.product-donation
	{
		display:flex; 
		flex-direction:column;

		.form-a
		{
			margin-bottom: 0px;
		}

		.form-b label.dropdown-label
		{
			margin-bottom: 0px;
		}

		.form-e
		{
			margin-bottom: 2rem;
		}

		.plus-minus
		{
			display: none;
		}
	}


	.in-kind-container
	{

		display: none;

		h3.product-type
		{
			color: get-color(ad-green);
			margin-top: 2rem;
		}

		table
		{
			margin-bottom: 3rem;
		}

		th
		{
			background-color: get-color(ad-border-color);

			h3
			{
				margin: 1rem;
			}
		}

		td
		{
			border-bottom:1px solid get-color(ad-border-color);
		}

		td:not(:last-child) 
		{
			border-right:1px solid get-color(ad-border-color);
		}

	}

	a#show-goods-list {
		display:block;
		position:relative;
		top:-180px;
		visibility:hidden;
	}

	@media all and (min-width: get-breakpoint(bantam))
	{
		.product-donation
		{
			display: block;
			flex-direction: row;

			.plus-minus
			{
				display: block;
			}
		}
	}
}