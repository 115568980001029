table
{
	width: 100%;
	margin-top: 3rem;

	thead
	{
		tr
		{
			th
			{
				background-color: get-color(ad-bg-gray);
				border-right: 1px solid white;

				padding: 1.5rem 1rem;

				color: get-color(ad-green);
				font-family: "Gunar-DemiBold";
				font-weight: normal;
				text-align: left;

				&:last-child
				{
					border-right: none;
				}
			}
		}
	}

	tr
	{
		border-bottom: 1px solid get-color(ad-bg-gray);

		td
		{
			padding: 3rem 1rem;
			vertical-align: top;

			a
			{
				color: get-color(ad-green);
			}
		}

		&:last-child
		{
			border-bottom: none;
		}
	}
}

table.distributor_list
{
	td:nth-of-type(5)
	{
		word-wrap: break-word;
	}
}

table.manufacturer_list,
table.distributor_list
{
	.company
	{
		min-width: 200px;
		max-width: 200px;
	}

	.logo
	{
		min-width: 200px;
		max-width: 200px;
	}
}

table.meeting_schedule
{
	td:nth-of-type(8)
	{
		word-wrap: break-word;
	}
}

@media all and(max-width: get-breakpoint(semi-small))
{
	table.distributor_list, table.manufacturer_list
	{
		display: block;

		/* Force table to not be like tables anymore */
		thead, tbody, th, td, tr {
			display: block;
		}

		table tr:last-child
		{
			border-bottom: 1px solid get-color(ad-bg-gray);
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr
		{
			border: 1px solid get-color(ad-bg-gray);
			margin-bottom: 1.5rem;
		}

		td {
			/* Behave  like a "row" */
			border-bottom: none;
			position: relative;
			padding: 1rem 1rem 1rem 50% !important;

			//&:last-child
			//{
			//	border-bottom: 1px solid get-color(ad-bg-gray);
			//}
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 1rem;
			left: 1rem;
			width: 45%;
			padding-right: 1rem;
			white-space: nowrap;

			color: get-color(ad-green);
			font-family: "Gunar-DemiBold";
		}
	}

	/*
		Label the data
		*/
	table.distributor_list
	{
		td:nth-of-type(1):before
		{
			content: "Company";
		}
		td:nth-of-type(2):before
		{
			content: "Address";
		}
		td:nth-of-type(3):before
		{
			content: "Contact";
		}
		td:nth-of-type(4):before
		{
			content: "Phone";
		}
		td:nth-of-type(5):before
		{
			content: "Website";
		}
	}

	table.manufacturer_list
	{
		td:nth-of-type(1):before
		{
			content: "Company";
		}
		td:nth-of-type(2):before
		{
			content: "Description";
		}
	}
}

@media all and(max-width: get-breakpoint(large))
{
	table.meeting_scheduledd
	{
		display: block;

		/* Force table to not be like tables anymore */
		thead, tbody, th, td, tr {
			display: block;
		}

		table tr:last-child
		{
			border-bottom: 1px solid get-color(ad-bg-gray);
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr
		{
			border: 1px solid get-color(ad-bg-gray);
			margin-bottom: 1.5rem;
		}

		td {
			/* Behave  like a "row" */
			border-bottom: none;
			position: relative;
			padding: 1rem 1rem 1rem 50% !important;

			//&:last-child
			//{
			//	border-bottom: 1px solid get-color(ad-bg-gray);
			//}
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 1rem;
			left: 1rem;
			width: 45%;
			padding-right: 1rem;
			white-space: nowrap;

			color: get-color(ad-green);
			font-family: "Gunar-DemiBold";
		}
	}

	table.manufacturer_list
	{
		.company
		{
			max-width: 100%;
		}

		.logo
		{
			max-width: 100%;
		}
	}

	/*
		Label the data
		*/
	table.meeting_scheduledd
	{
		td:nth-of-type(1):before
		{
			content: "Dates";
		}
		td:nth-of-type(2):before
		{
			content: "Suggested \a Arrival/Departure";
			white-space: pre;
		}
		td:nth-of-type(3):before
		{
			content: "Division";
		}
		td:nth-of-type(4):before
		{
			content: "Meeting";
		}
		td:nth-of-type(5):before
		{
			content: "Location";
		}
		td:nth-of-type(6):before
		{
			content: "City";
		}
		td:nth-of-type(7):before
		{
			content: "State";
		}
		td:nth-of-type(8):before
		{
			content: "More Information";
		}
	}
}
