/**
 * Fonts
 * ----------------------------------------------------------------------------
 * I'm a simple guy with simple needs, I don't like to mix a lot of different
 * fonts in my designs.  To keep it simple, I have two different font types:
 * copy and headlines.  They are exactly what they sound like.
 *
 * Make sure you also define "fallbacks" on the off chance Google fonts goes
 * down or your user is on a crappy connection.
 *
 */

$stack-settings: (
   heading:                  #{"Gunar-Heavy", Georgia, serif},
   sub-heading:              #{"Gunar-Heavy", Georgia, serif},
   copy:                     #{"Archivo", "Helvetica Neue", sans-serif},
   base-size:                62.5%,
   line-height:              1.5,
   letter-spacing:           0,
   letter-spacing-headlines: -1px,
);

$font-weights: (
   thin:         100,
   extra-light:  200,
   light:        300,
   normal:       400,
   medium:       500,
   semi-bold:    600,
   bold:         700,
   extra-bold:   800,
   ultra-bold:   900,
);

$breakpoints: (
   'puny':       240px,
   'tiny':       320px,
   'bitty':      480px,
   'small':      640px,
   'semi-small': 680px,
   'bantam':     768px,
   'medium':     980px,
   'large':      1024px,
   'xlarge':     1160px,
   'xxlarge':    1280px,
   'jumbo':      1440px,
   'hulking':    1600px,
   'titanic':    1920px
);

$colors: (
   black:             #000,
   white:             #fff,
   charcoal:          #444,
   ad-dark-blue:      #333F48,
   ad-black:          #2A2828,
   ad-gray:           #7C878E,
   ad-dark-gray:      #222222,
   ad-light-gray:     #768692,
   ad-bg-gray:        #EFF0EF,
   ad-border-color:   #E5E5E5,
   ad-green:          #44693D,
   ad-light-green:    #6FA287,
   ad-yellow:         #F8E08E,
   ad-error:          #8B1820,
   ad-blue:           #24b1d0,
);

$outer-container-max-width: 1920px;
$inner-container-max-width: 1440px;
$sub-container-max-width: 1260px;
$narrow-container-max-width: 900px;

@include layout((
  columns: 16,
  gutter-position: after,
  gutters: 20/70,
  global-box-sizing: border-box
));

$current-zindex:        100;
