.footer
{
	background-color: #222222;
	color: get-color(white);

	.alert-signup
	{
		text-align: center;

		h1
		{
			color: get-color(white);
			@include font-size(20px);
			line-height: normal;
		}

		.form
		{
			margin-top: 22px;
			display: flex;
			justify-content: space-between;

			input[type="email"]
			{
				color: #a29b9b;
				background-color: #404040;
				border: none;
				margin-right: 10px;
				@include font-size(12px);
				width: 100%;
			}
		}
	}
	
	.logo-legal
	{
		margin-top: 36px;
		text-align: center;

		.logo-donation-info
		{
			flex-direction: column;
			align-items: center;
		}

		img
		{
			width: 121px;
			order: 2;
			margin-top: 3rem;
		}

		p
		{
			@include font-size(12px);
		}

		.mobile-social
		{
			display: flex;
			justify-content: space-between;

			margin: 16px auto 0 auto;
			width: 60px;
			@include font-size(20px);
			font-weight: get-font-weight(ultra-bold);
		}
	}
}

.footer-copyright
{
	background-color: #1c1b1b;
	color: get-color(white);
	@include font-size(12px);

	text-align: center;

	.contain
	{
		padding-top: 18px;
		padding-bottom: 18px;
	}

	img
	{
		width: 80px;
	}

	.social
	{
		display: none;
	}
}

@media all and(min-width: get-breakpoint(semi-small))
{
	.footer-copyright .contain
	{
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > *
		{
			flex: 1;
		}

		.left
		{
			text-align: left;
		}

		.right
		{
			text-align: right;
		}

		img
		{
			width: 90px;
		}
	}
}

@media all and(min-width: get-breakpoint(bantam))
{
	.footer .contain
	{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;

		.logo-legal
		{
			order: 1;
			width: 64%;
			text-align: left;
			margin-top: 0;

			.logo-donation-info
			{
				flex-direction: row;
				align-items: normal;
			}					

			img
			{
				width: 152px;
				height:100%; 
				padding-top:10px;
			}

			p
			{
				border-top: solid 1px #3c3c3c;
				padding-top: 1rem;
			}
		}

		.alert-signup
		{
			order: 3;
			width: 25%;
		}

		.donation-info
		{
			width: 48%;
			order: 2;
			margin-left: 7rem;
			p {
				border-top-style: none;
				margin: 0px;
			}
		}

		.mobile-social
		{
			display: none;
		}
	}

	.footer-copyright
	{
		.social
		{
			display: block;
			@include font-size(30px);
			font-weight: get-font-weight(ultra-bold);

			text-align: center;
			a
			{
				margin: 0 .25rem;
			}
		}
	}
}

@media all and(min-width: get-breakpoint(medium))
{
	.footer .contain
	{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		
		.logo-legal
		{
			.mobile-social
			{
				display: none;
			}
		}

		.alert-signup
		{
			order: 3;
			text-align: left;
		}

		.social
		{
			text-align: left;
			margin-top: 0;
			
			a
			{
				display: block;
				margin-bottom: 1rem;
			}
		}
	}
}
