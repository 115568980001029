//For CMS WYSIWYG
#content-area
{
	background-color: get-color(white);
	padding: 1.5rem;
}

.content
{
	margin-top: 90px;
	background-color: #ECEDEE;
}

.home
{
	.content
	{
		background-color: transparent;
	}
}

.wrapper
{
	//max-width: $outer-container-max-width;
	margin: 0 auto;
	position: relative;
	z-index: 3;
}

.home.wrapper
{
	z-index: auto;
}

.contain
{
	max-width: $inner-container-max-width;
	padding: 4rem 3rem;
	margin: 0 auto;

	&--narrow
	{
		@extend .contain;
		max-width: $narrow-container-max-width;
	}

	&--xnarrow
	{
		@extend .contain;
		max-width: 700px;
	}

	&--medium
	{
		@extend .contain;
		max-width: 1015px;
	}

	&--tight
	{
		@extend .contain;
		padding: 2rem 1.5rem 1rem 1.5rem;
	}

	&--short
	{
		@extend .contain;
		padding: 3rem;
	}
}

.inner-contain
{
	@include clearfix();

	max-width: $sub-container-max-width;
	margin: 0 auto;

	&--small
	{
		max-width: get-breakpoint(large);
		margin: 0 auto;
	}
}

.video-shade, .shade
{
	display: none;
	background-color: rgba(#2A2828, .91);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 112;
}

.video-shade
{
	.fa-close
	{
		position: absolute;
		color: get-color(white);
		@include font-size(36px);
		top: 2rem;
		right: 2rem;

		cursor: pointer;
	}
}

.embed-container {
	position: relative !important;
	padding-bottom: 56.25% !important;
	height: 0 !important;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-embed-container
{
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	iframe, object, embed
	{
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;


		max-height: 100vh;
		top: 50%;
		transform: translate(0, -50%);
	}
}

.video-embed-container--award
{
	@extend .video-embed-container;
	transform: translate(-50%, 0);
	margin-bottom: 1.5rem;

}

.standard-hero
{
	display: block;
	margin: 0 auto;
}

.intro-section
{
	line-height: 32px;
	@include font-size(18px);

	&--blue
	{
		@extend .intro-section;
		color: get-color(white);

		h1
		{
			color: get-color(white);
		}

		h3
		{
			color: get-color(ad-yellow);
		}

	}
}

.bg-breakout
{
	width: 100%;
	&--blue
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/minified/blue-texture.jpg');
	}

	&--blue-dark
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/minified/blue-texture-dark.jpg');
	}

	&--blue-video
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/minified/blue-texture.jpg');
		height: 25vw;
	}

	&--blue-grid
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/minified/blue-texture-grid.jpg');
	}

	&--blue-grid-vertical
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/minified/blue-texture-grid-vertical.jpg');

		@media all and (min-width:  get-breakpoint(semi-small))
		{
			background-position: center center;
		}
	}

	&--blue-solid
	{
		@extend .bg-breakout;
		background-color: get-color(ad-dark-blue);
	}

	&--green
	{
		@extend .bg-breakout;
		background-image: url('../../assets/images/green-texture.jpg');
	}

	&--white
	{
		@extend .bg-breakout;
		background: get-color(white);
	}

	&--gray
	{
		@extend .bg-breakout;
		background: get-color(ad-bg-gray);
	}

	&--dark-gray
	{
		@extend .bg-breakout;
		background: get-color(ad-dark-gray);
	}

	&--light-gray
	{
		@extend .bg-breakout;
		background: get-color(ad-light-gray);
	}
}

blockquote
{
	margin: 0 0 4rem 0;
	padding: 0;

	color: get-color(white);
	font: {
		family: get-font(sub-heading);
		weight: get-font-weight(normal);
	}
	@include font-size(24px);
	letter-spacing: 0.2px;
	line-height: 38px;

	p:first-child
	{
		&:before
		{
			content: '"'
		}
	}

	p:last-child
	{
		&:after
		{
			content: '"'
		}
	}
}

.no-scroll
{
	overflow: hidden;
}

.text-white
{
	color: get-color(white);

	h1, h2
	{
		color: get-color(white);
	}
}

.text-yellow
{
	color: get-color(ad-yellow);
}

.text-green
{
	color: get-color(ad-green);
}

.text-gray
{
	color: get-color(ad-gray)
}

.uppercase
{
	text-transform: uppercase;
}

.underline
{
	text-decoration: underline;
}

.semi-bold
{
	font-family: "Gunar-DemiBold";
}

.bold
{
	font-family: "Gunar-Bold";
}



@media all and (max-width: ( get-breakpoint(semi-small) - 1) ) {
	.hide-desktop
	{
		display: inherit;
	}

	.hide-mobile
	{
		display: none !important;
	}
}

@media screen and (min-width: get-breakpoint(semi-small) )
{
	.contain
	{
		padding: 9rem 3rem;
	}

	.contain--short
	{
		padding: 6rem 3rem;
	}

	.contain.left
	{
		> div *:not(div)
		{
			max-width: 465px;
		}
	}

	.hide-desktop
	{
		display: none !important;
	}

	.hide-mobile
	{
		display: inherit;
	}

	.intro-section
	{
		@include container;
		max-width: $sub-container-max-width;

		.intro-left
		{
			@include span(first 6 of 14);
			position: relative;
			padding-left: 2rem;

			&:before
			{
				position: absolute;
				content: '';
				left: 0;
				top: 13px;
				bottom: 15px;
				width: 5px;
				background-color: get-color(ad-light-gray);
			}

			h1
			{
				@include font-size(42px);
				line-height: 5rem;
			}

			h3
			{
				margin-bottom: 0;
			}
		}

		.intro-right
		{
			@include span(last 8 of 14);
		}
	}

	.split-hero-flex
	{
		//@include flex-container(center, flex-start);

		> div
		{
			width: 48%;
		}
	}
}

@media all and (min-width: get-breakpoint(medium) )
{

	.content
	{
		margin-top: 116px;
	}

	//.no-scroll
	//{
	//	overflow: auto !important;
	//}
}

@media screen and (min-width: $sub-container-max-width)
{
	.video-poster
	{
		width: 100%;
		left: 0;
	}

	.standard-hero
	{
		max-width: $sub-container-max-width;
		padding-top: 5rem;
	}
}

.bx-viewport li {
	min-height: 1px;
	min-width: 1px;
}

@media all and (min-width: get-breakpoint(jumbo))
{
	.contain.left
	{
		> div *:not(div)
		{
			max-width: 565px;
		}
	}
}
