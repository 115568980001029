.page-module--hero
{
	position: relative;
	overflow: hidden;

	.hero-content
	{
		position: relative;
		z-index: 2;
	}

	.hero-bg-image
	{
		position: absolute;
		z-index: 1;

		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-size: cover;
		background-position: center right;

		transform: scale(1.1);

		animation-name: zoom;
		animation-delay: 0.5s;
		animation-duration: 5s;
		animation-fill-mode: forwards;
	}

	color: get-color(white);

	h1
	{
		color: get-color(white);
	}
}

@media all and(min-width: get-breakpoint(medium))
{
	.page-module--hero
	{
		min-height: 74vh;
		padding-top: 0;
	}
}

@keyframes zoom
{
	from{
		transform: scale(1.1);
	}

	to{
		transform: scale(1);
	}
}
