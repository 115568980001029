.home
{
	.recent-needs
	{
		img
		{
			display: block;
			margin: 0 auto 1rem auto;
		}
	}

	.disaster-container > .contain
	{
		padding: 0;
	}

	.disaster-block
	{
		color: get-color(white);
		//min-height: 435px;
		padding-top: 227px;
		background-size: cover;
		background-position: center center;

		h2
		{
			color: get-color(white);
			margin-bottom: 0;
		}

		p
		{
			margin-top: 12px;
			@include font-size(16px);
		}
	}
}

@media all and(min-width: get-breakpoint(semi-small))
{
	.home
	{
		.recent-needs
		{
			h4
			{
				position: relative;
				z-index: 2;
				background-color: white;
				padding: 0 40px;
				display: inline-block;
			}
		}

		.disaster-container > .contain
		{
			display: flex;
			flex-wrap: wrap;
		}

		.disaster-block
		{
			width: 50%;
		}

		.what-is
		{
			text-align: center;
		}
	}
}

@media all and(min-width: get-breakpoint(medium))
{
	.home
	{
		.disaster-breakout
		{
			padding-bottom: 3rem;
		}

		.disaster-container
		{
			padding: 86px 36px 3px 36px;
			background-color: get-color(white);
			border: solid 1px #eff0ef;
			margin: -12.5rem 3rem 0 3rem;
		}

		.disaster-container > .contain
		{
			justify-content: space-between;
			background-color: get-color(white);
			//padding: 9rem 3rem;
		}

		.disaster-block
		{
			width: 48%;
			padding-top: 0;
			margin-bottom: 3rem;

			display: flex;
			align-items: flex-end;

			.contain
			{
				padding: 0 3rem 0 0;
				flex: 1;
			}

			.inner
			{
				padding: 200px 0 20px 20px;
			}
		}
	}
}
