/**
 * Elements are global styles.  They are very generic, in fact, you
 * should think long and hard before adding anything to this stylesheet.
 * Do not add font sizes or colors in here...yes even if it's global
 * because the truth is it probably isn't as global as you think.
 */
* {
   box-sizing: inherit;
   -webkit-overflow-scrolling: touch;
   &:before, &:after {
      box-sizing: inherit;
   }

   -moz-font-feature-settings:"liga" 0;
   -ms-font-feature-settings:"liga" 0;
   -o-font-feature-settings:"liga" 0;
   -webkit-font-feature-settings:"liga" 0;
   font-feature-settings:"liga" 0;
}

html {
   box-sizing: border-box;
   font: {
      family: get-font(copy);
      size: get-font(base-size);
      weight: get-font-weight(normal);

   }
   height: 100%;
   letter-spacing: get-font(letter-spacing);
}

// :focus {
//    outline: none;
// }

body {
   background: #d7d7d7;
   color: get-color(ad-black);
   @include font-size(16px);

   //height: 100%;
   margin: 0;
   min-width: get-breakpoint(tiny);
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

img {
   height: auto;
   max-width: 100%;
}

figure
{
   margin: 0;
}

h1, h2, h3, h4, h5, h6 {
   color: get-color(ad-black);
   font: {
      family: get-font(heading);
      weight: get-font-weight(ultra-bold);
   }
   letter-spacing: get-font(letter-spacing-headlines);
   line-height: normal;
   margin: 0;
   padding: 0;

   text-transform: uppercase;
}

h1
{
   @include font-size(40px);
   letter-spacing: 0.66px;
   line-height: 0.94;
}

h2
{
   @include font-size(32px);
   margin-bottom: 30px;
}

h3
{
   @include font-size(20px);
   letter-spacing: 0.75px;
   //line-height: 5rem;
   text-transform: uppercase;

   margin-bottom: 2rem;

	&.subtitle
	{
		margin-top: 2rem;
	}
}

h4
{
   @include font-size(24px);
   margin-bottom: 20px;
}

b, strong {
   font-family: "Gunar-Bold", "Helvetica Neue", sans-serif;
   font-weight: normal;
}

p, ul, ol {
   font-size: inherit;
}

a {
   color: inherit;
   font: {
      family: inherit;
      weight: inherit;
   }
   text-decoration: none;
   
   &.back-link
   {
      display: block;
      margin-bottom: 5rem;

      .fa
      {
         display: inline-block;
         margin-right: 1rem;
      }
   }
}

p
{
   a
   {
      color: get-color(ad-green);
      text-decoration: underline;
   }
}

p {
   margin: 0 0 24px 0;
   padding: 0;
   line-height: 1.38;

   &.xlarge
   {
      @include font-size(22px);
      line-height: 1.45;
      font-family: get-font(heading);
      font-weight: get-font-weight(ultra-bold);
   }
   
   &:not(:first-child)
   {
      margin-top: 24px;
   }
   
   &:last-child
   {
      margin-bottom: 0;
   }

   @media all and(min-width: get-breakpoint(semi-small))
   {
      @include font-size(18px);
      line-height: 1.6;

      &.large
      {
         @include font-size(22px);
         line-height: 1.45;
      }

      &.xlarge
      {
         @include font-size(30px);
         line-height: 1.33;
      }
   }

}

ol, ul
{
   //padding: 0;
   //margin: 0;
}

nav {
   ol, ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
         margin: 0;
         padding: 0;
      }
   }
}

hr
{
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid get-color(ad-border-color);
      margin: 3rem 0;
      padding: 0;
}

.no-top-padding
{
   padding-top: 0 !important;
}

.no-bottom-padding
{
   padding-bottom: 0 !important;
}

.hidden
{
	display: none;
}

@media all and (min-width: get-breakpoint(semi-small) )
{
   body
   {
      //line-height: 30px;
   }

   h1
   {
      @include font-size(48px);
   }

   h2
   {
      @include font-size(30px);
     // margin-bottom: 40px;
   }

   .large p
   {
      @extend p.large;
   }
}

@media all and (min-width: get-breakpoint(jumbo) )
{
   h1
   {
      @include font-size(65px);
   }
}

.no-hover
{
   display: none;
   @media (hover: none) {
      display: block;
   }
}
